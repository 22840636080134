import s from "./styles.module.sass";

export const FooterIcon = () => {
  return (
    <div className={s.footerIcon}>
      <svg
        width="1342"
        height="163"
        viewBox="0 0 1342 163"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            d="M63.2304 163C43.7979 163 28.4014 157.626 17.0408 146.879C5.68028 135.983 0 120.459 0 100.308V62.6923C0 42.5412 5.68028 27.092 17.0408 16.3448C28.4014 5.44826 43.7979 0 63.2304 0C82.5135 0 97.3868 5.299 107.85 15.897C118.464 26.3457 123.77 40.75 123.77 59.1099V60.4533H94.6214V58.2143C94.6214 48.9597 92.0055 41.3471 86.7737 35.3764C81.6913 29.4057 73.8436 26.4203 63.2304 26.4203C52.7668 26.4203 44.5453 29.6296 38.5661 36.0481C32.5868 42.4666 29.5972 51.1987 29.5972 62.2445V100.756C29.5972 111.652 32.5868 120.384 38.5661 126.952C44.5453 133.37 52.7668 136.58 63.2304 136.58C73.8436 136.58 81.6913 133.594 86.7737 127.624C92.0055 121.504 94.6214 113.891 94.6214 104.786V100.756H123.77V103.89C123.77 122.25 118.464 136.729 107.85 147.327C97.3868 157.776 82.5135 163 63.2304 163Z"
            fill="white"
          />
          <path
            d="M138.917 159.865V3.13461H168.515V67.842H226.812V3.13461H256.409V159.865H226.812V94.7102H168.515V159.865H138.917Z"
            fill="white"
          />
          <path
            d="M266.331 159.865L307.588 3.13461H359.159L400.416 159.865H369.922L361.401 125.385H305.346L296.825 159.865H266.331ZM312.297 98.0687H354.45L335.391 21.7184H331.355L312.297 98.0687Z"
            fill="white"
          />
          <path
            d="M410.234 159.865V3.13461H466.514L497.68 139.714H501.716V3.13461H530.865V159.865H474.586L443.419 23.2857H439.383V159.865H410.234Z"
            fill="white"
          />
          <path
            d="M605.738 163C594.975 163 585.184 160.612 576.365 155.835C567.695 150.909 560.744 143.819 555.512 134.565C550.43 125.161 547.888 113.742 547.888 100.308V62.6923C547.888 42.5412 553.569 27.092 564.929 16.3448C576.29 5.44826 591.686 0 611.119 0C630.402 0 645.275 5.14973 655.739 15.4492C666.352 25.5994 671.659 39.4066 671.659 56.8709V57.7665H642.51V55.9753C642.51 47.6163 639.894 40.6007 634.662 34.9286C629.58 29.2564 621.732 26.4203 611.119 26.4203C600.655 26.4203 592.434 29.6296 586.455 36.0481C580.475 42.4666 577.486 51.1987 577.486 62.2445V100.756C577.486 111.652 580.475 120.459 586.455 127.176C592.434 133.744 600.805 137.027 611.567 137.027C622.33 137.027 630.178 134.191 635.111 128.519C640.043 122.847 642.51 115.682 642.51 107.025V104.786H605.289V79.7088H671.659V159.865H644.304V144.864H640.268C639.221 147.402 637.502 150.088 635.111 152.924C632.868 155.761 629.43 158.149 624.796 160.089C620.162 162.03 613.81 163 605.738 163Z"
            fill="white"
          />
          <path
            d="M690.966 159.865V3.13461H791.866V30.0027H720.563V67.3942H785.588V94.2624H720.563V132.997H793.211V159.865H690.966Z"
            fill="white"
          />
          <path
            d="M860.413 163C849.65 163 839.859 160.612 831.04 155.835C822.37 150.909 815.419 143.819 810.187 134.565C805.105 125.161 802.564 113.742 802.564 100.308V62.6923C802.564 42.5412 808.244 27.092 819.604 16.3448C830.965 5.44826 846.362 0 865.794 0C885.077 0 899.951 5.14973 910.414 15.4492C921.027 25.5994 926.334 39.4066 926.334 56.8709V57.7665H897.185V55.9753C897.185 47.6163 894.569 40.6007 889.337 34.9286C884.255 29.2564 876.407 26.4203 865.794 26.4203C855.33 26.4203 847.109 29.6296 841.13 36.0481C835.15 42.4666 832.161 51.1987 832.161 62.2445V100.756C832.161 111.652 835.15 120.459 841.13 127.176C847.109 133.744 855.48 137.027 866.242 137.027C877.005 137.027 884.853 134.191 889.786 128.519C894.719 122.847 897.185 115.682 897.185 107.025V104.786H859.964V79.7088H926.334V159.865H898.979V144.864H894.943C893.897 147.402 892.177 150.088 889.786 152.924C887.544 155.761 884.105 158.149 879.472 160.089C874.838 162.03 868.485 163 860.413 163Z"
            fill="white"
          />
          <path
            d="M1006.18 163C986.898 163 971.801 157.776 960.888 147.327C950.126 136.878 944.745 122.698 944.745 104.786V3.13461H974.342V105.681C974.342 115.234 977.032 122.772 982.414 128.295C987.945 133.818 995.867 136.58 1006.18 136.58C1016.5 136.58 1024.34 133.818 1029.72 128.295C1035.26 122.772 1038.02 115.234 1038.02 105.681V3.13461H1067.62V104.786C1067.62 122.698 1062.16 136.878 1051.25 147.327C1040.49 157.776 1025.46 163 1006.18 163Z"
            fill="white"
          />
          <path
            d="M1087.32 159.865V3.13461H1155.48C1165.35 3.13461 1173.95 4.85119 1181.27 8.28434C1188.59 11.7175 1194.27 16.5687 1198.31 22.8379C1202.35 29.1071 1204.36 36.4959 1204.36 45.0041V47.6909C1204.36 57.0948 1202.12 64.7074 1197.64 70.5288C1193.15 76.3503 1187.62 80.6044 1181.05 83.2912V87.3214C1187.03 87.62 1191.66 89.7097 1194.95 93.5907C1198.24 97.3223 1199.88 102.323 1199.88 108.592V159.865H1170.28V112.846C1170.28 109.264 1169.31 106.353 1167.37 104.114C1165.57 101.875 1162.51 100.756 1158.18 100.756H1116.92V159.865H1087.32ZM1116.92 73.8874H1152.35C1159.37 73.8874 1164.83 72.0215 1168.71 68.2898C1172.75 64.4089 1174.77 59.3338 1174.77 53.0646V50.8256C1174.77 44.5563 1172.82 39.5559 1168.94 35.8242C1165.05 31.9432 1159.52 30.0027 1152.35 30.0027H1116.92V73.8874Z"
            fill="white"
          />
          <path
            d="M1280.56 163C1261.28 163 1246.18 157.776 1235.27 147.327C1224.51 136.878 1219.13 122.698 1219.13 104.786V3.13461H1248.72V105.681C1248.72 115.234 1251.41 122.772 1256.8 128.295C1262.33 133.818 1270.25 136.58 1280.56 136.58C1290.88 136.58 1298.73 133.818 1304.11 128.295C1309.64 122.772 1312.4 115.234 1312.4 105.681V3.13461H1342V104.786C1342 122.698 1336.54 136.878 1325.63 147.327C1314.87 157.776 1299.85 163 1280.56 163Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};
