import { PATH_PAGE } from "shared/config";

export const breadcrumbsList = [
  {
    link: PATH_PAGE.root,
    title: "Home",
  },
  {
    link: PATH_PAGE.register,
    title: "Register",
  },
];
